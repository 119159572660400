/**
 *  offers
 * @type {{}}
 */
import Vue from "vue";
import VueLazyLoad from "vue-lazyload";
import { getOffersListService } from "@/service/system-service";
import { mapMutations } from "vuex";
import { SOURCE_NAME } from "@/service/public/enum";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import { wbAccess, wxAccess } from "@/utils/share";
import { isPhone } from "@/utils/tools";
Vue.use(VueLazyLoad, {
  loading: require("@/assets/Loading/seize-seat-img.jpg")
});
const Offers = {
  name: "Offers",
  data() {
    return {
      loadingVisible: false,
      offersObj: {}, //offers数据
      offersSearch: "", //搜索条件
      brandOrStorePlaceholder: this.$t("offers.brandOrStore"),
      isPhone: isPhone
    };
  },
  created() {
    $event.$emit(EVENT_NAME.HEADER_MENU_TYPE, 5);
    this.onOffersList().then();
  },
  mounted() {
    document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
  },
  methods: {
    ...mapMutations("product", [
      "SET_SOURCE",
      "SET_MENU_ID",
      "SET_SELECTION_ID",
      "SET_MENU_TYPE",
      "SET_SECOND_TYPE",
      "SET_TERTIARY_TYPE",
      "SET_LEVEL_TYPE",
      "SET_IS_NEW",
      "SET_IS_SALE"
    ]),
    onFocus() {
      this.brandOrStorePlaceholder = "";
    },
    onBlur() {
      this.brandOrStorePlaceholder = this.$t("offers.brandOrStore");
    },
    /**
     * 分享配置
     * @param config
     * @param obj
     */
    onShare(config, obj) {
      switch (config) {
        case "wb":
          wbAccess(obj.source, obj.logoImage, obj.link);
          break;
        case "wx":
          wxAccess("");
          break;
      }
    },
    /**
     * 跳转列表
     * @param cid
     */
    onOffersLink(obj) {
      if (obj.contentType === 1) {
        window.open(obj.link, "_blank");
      } else {
        if (JSON.stringify(obj) === "{}") {
          return;
        }
        this.SET_SELECTION_ID(obj.selectionId);
        this.SET_MENU_ID(obj.id);
        this.SET_SOURCE(SOURCE_NAME.SUPER_SALE);
        this.SET_SECOND_TYPE({
          categoryId: "",
          name: ""
        });
        this.SET_TERTIARY_TYPE({
          categoryId: "",
          name: ""
        });
        this.$router.push("/product/list");
      }
    },
    onQuery() {
      this.onOffersList().then();
    },
    /**
     * 查询特惠
     * @returns {Promise<void>}
     */
    async onOffersList() {
      try {
        this.loadingVisible = true;
        let params = {
          size: 999,
          page: 1,
          source: this.offersSearch
        };
        const data = await getOffersListService(params);
        this.offersObj = data;
        this.loadingVisible = false;
      } catch (error) {
        this.loadingVisible = false;
        console.log(error);
      }
    }
  }
};
export default Offers;
