<template>
  <div class="layout-offers">
    <!--s: Search-->
    <div class="offers-search">
      <div class="box">
        <div class="input-box">
          <input
            v-model="offersSearch"
            @focus="onFocus('account')"
            @blur="onBlur('account')"
            @keyup.enter="onQuery"
            type="text"
            class="input"
            :placeholder="brandOrStorePlaceholder"
          />
          <a
            href="javascript:"
            v-if="offersSearch !== ''"
            @click="offersSearch = ''"
          ></a>
        </div>
        <a class="search-icon" href="javascript:" @click="onQuery"></a>
      </div>
    </div>
    <!--e: Search-->

    <!--s: List-->
    <div class="offers-list">
      <ul>
        <li :key="key" v-for="(offers, key) in offersObj.content">
          <div class="offers-img-container">
            <img
              :src="offers.logoImage"
              v-lazy="offers.logoImage"
              class="offers-image"
            />
          </div>
          <div class="offers-exclusive">
            <span class="name" v-if="offers.exclusive !== 0">{{
              $t("offers.exclusive")
            }}</span>
            <span
              class="number"
              :style="offers.exclusive === 0 ? 'width:100%' : ''"
              >{{ offers.saleContent }}</span
            >
          </div>
          <div class="content">
            <div class="box" v-html="offers.saleText"></div>
          </div>
          <div class="code">
            {{ $t("offers.code") }}: <b>{{ offers.code }}</b>
          </div>
          <div class="time">
            {{ $t("offers.expire") }}: {{ offers.endTime }}
          </div>
          <div class="show">
            <a href="javascript:" @click="offers.visible = !offers.visible"
              >{{ offers.visible ? $t("offers.hide") : $t("offers.share") }}
              <i class="icon" :class="offers.visible ? 'up' : 'down'"></i
            ></a>
          </div>
          <div class="offers-btn" v-if="!offers.visible">
            <a
              href="javascript:"
              class="btn"
              @click="onOffersLink(offers)"
              v-html="isPhone() ? $t('menu.shopNow') : $t('offers.shopNow')"
            ></a>
          </div>
          <div class="offers-share" v-if="offers.visible">
            <div class="close-share" v-if="isPhone()" @click="offers.visible = false"></div>
            <div class="box">
              <a href="javascript:" class="face-book"></a>
              <a href="javascript:" class="pinterest"></a>
              <a href="javascript:" class="twitter"></a>
              <a
                href="javascript:"
                @click="onShare('wb', offers)"
                class="sina"
              ></a>
            </div>
            <div class="box">
              <a href="javascript:" class="wx"></a>
              <a href="javascript:" class="email"></a>
              <a href="javascript:" class="icon-code"></a>
              <a href="javascript:" class="link"></a>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!--e: List-->

    <!--s: Loading-->
    <div class="offers-loading" v-if="loadingVisible"></div>
    <!--e: Loading-->

    <!--s: No data-->
    <div
      class="offers-info"
      v-if="offersObj.content && offersObj.content.length === 0"
    >
      <h1 class="title">{{ $t("offers.title") }}</h1>

      <p
        class="content"
        v-html="$t('offers.content', { text: offersSearch })"
      ></p>
    </div>
    <!--e: No data-->
  </div>
</template>
<script>
import Offers from "./offers";
export default Offers;
</script>
<style lang="less" scoped>
@import "offers";
</style>
